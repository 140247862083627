import state from './modulePromoManagementState.js'
import mutations from './modulePromoManagementMutations.js'
import actions from './modulePromoManagementActions.js'
import getters from './modulePromoManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

