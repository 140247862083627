<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Edit Promo</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="6">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Promo Category (*)</span>
            <v-select
              label="name"
              :options="promoCategoryOptions"
              :reduce="category => category.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.promo_category_id"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("promo_category_id")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Promo Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              placeholder="Keep empty if you wanna auto-generate code"
              label="Promo Code"
              v-model="payload.promo_code"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("promo_code")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              type="number"
              class="w-full"
              min="1"
              max="100"
              label="Promo Value (*)"
              v-model="payload.promo_value"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("value")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Expired At (*)</span>
            <flat-pickr
              class="w-full"
              :config="configdateTimePicker"
              v-model="payload.expired_at"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("expired_at")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Summary</span>
            <vs-textarea v-model="payload.summary" />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("summary")
            }}</span>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col vs-w="6" class="pl-8">
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="url" class="rounded mr-4" width="350" height="350" />
          <div>
            <input
              type="file"
              @change="addImage"
              class="hidden"
              ref="update_logo_input"
              accept="image/*"
            />
            <vs-button
              @click="$refs.update_logo_input.click()"
              class="mr-4 mb-2"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
            ></vs-button>
            <vs-button
              @click="removeImage()"
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
            ></vs-button>
          </div>
        </div>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("image")
        }}</span>
      </vs-col>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">Description</span>
        <input type="file" @change="uploadMedia" id="file" hidden />
        <quill-editor
          v-model="payload.description"
          :options="editorOption"
          @ready="onEditorReady($event)"
          ref="myQuillEditor"
        ></quill-editor>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("description")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageDrop', ImageDrop);

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

// Store Module
import modulePromoManagement from "@/store/promo-management/modulePromoManagement.js";

export default {
  name: "PromoEdit",

  metaInfo: {
    title: "Edit Promo",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect,
    quillEditor,
    flatPickr
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },

  watch: {
    url(url) {
      if (url == null) {
        this.url = "https://via.placeholder.com/350";
      } else {
        this.url = url;
      }
    }
  },

  data: () => ({
    promoCategoryOptions: [],
    payload: {
      promo_category_id: '',
      name: "",
      promo_code: "",
      promo_value: "",
      expired_at: null,
      summary: "",
      description: "",
      image: ""
    },
    url: null,
    loading: true,
    configdateTimePicker: {
      enableTime: true,
      dateFormat: "d-m-Y H:i"
    },
    editorOption: {
      // some quill options
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
            ["link", "image"]
          ],
          handlers: {
            image: function() {
              document.getElementById("file").click();
            }
          }
        },
        imageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar']
        },
        imageDrop: true
      }
    }
  }),

  methods: {
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },

    uploadMedia(e) {
      const file = e.target.files[0];

      const form = new FormData();
      form.append("media", file);

      axios
        .post("promo-media", form, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(({ data: res }) => {
          // set position cursor
          const range = this.editor.getSelection();
          // set image on the server to quill editor
          this.editor.insertEmbed(range.index, "image", res.data);
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.image = file;
    },

    removeImage() {
      this.url = null;
      this.payload.image = "";
    },

    getPromoCategories() {
      axios
        .get("/promo-category")
        .then(({ data: res }) => {
          this.promoCategoryOptions = res.data.map(category => {
            return {
              id: category.id,
              name: category.name
            };
          });
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    getData() {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store
        .dispatch("promoManagement/fetchPromo", this.decryptedID)
        .then(({ data: res }) => {
          this.payload.promo_category_id = res.data.promo_category.id;
          this.payload.name = res.data.name;
          this.payload.promo_code = res.data.promo_code;
          this.payload.promo_value = res.data.value * 100;
          this.payload.expired_at = res.data.expired_at;
          this.payload.summary = res.data.summary !== null ? res.data.summary : '';
          this.payload.description = res.data.description !== null ? res.data.description : '';
          this.url = res.data.image != null ? res.data.image : null;
        })
        .catch(err => this.$catchErrorResponse(err))
        .finally(res => {
          this.loading = false
          this.$vs.loading.close()
        })
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("promo_category_id", this.payload.promo_category_id);
      formData.set("name", this.payload.name);
      formData.set("promo_code", this.payload.promo_code);
      formData.set("value", this.payload.promo_value);
      formData.set("expired_at", this.payload.expired_at);
      if (this.payload.image != "") {
        formData.set("image", this.payload.image);
      }
      if (this.payload.description != "") {
        formData.set("description", this.payload.description);
      }
      if (this.payload.summary != "") {
        formData.set("summary", this.payload.summary);
      }
      formData.set("_method", "PUT");

      this.$store
        .dispatch("promoManagement/updatePromo", {
          id: this.decryptedID,
          body: formData
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/promos");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  mounted() {
    if (this.url == null) {
      this.url = "https://via.placeholder.com/350";
    }
  },

  created() {
    if (!modulePromoManagement.isRegistered) {
      this.$store.registerModule("promoManagement", modulePromoManagement);
      modulePromoManagement.isRegistered = true;
    }

    this.getPromoCategories();
    this.getData();
  }
};
</script>

<style></style>
